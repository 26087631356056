import { useContext } from 'react';

import { ComponentThemeProviderContext } from '../helpers/providers/theme/theme-provider';

import type { availableThemes, TBaseComponentTheme } from '../config/themes/componentThemes';

type TemporaryThemeOptions = {
  accent?: {
    border?: boolean;
    heading?: boolean;
    hover?: boolean;
    icon?: boolean;
  };
  additionalThemeOptions?: Record<string, unknown>;
  /** Child theme should be accessed by wrapping the child component with `<ChildComponentThemeProvider />`. */
  childTheme?: {
    base: ValuesOf<typeof availableThemes>;
  } & Omit<TemporaryThemeOptions, 'childTheme'>;
};

/**
 * Provides theme definitions from the property-ui configuration to the component.
 *
 * **⚠️ Important:** This hook requires the component be rendered dynamically through the component mapper or individually wrapped with `<ComponentThemeProvider />`.
 */
export const useTheme = () => {
  const themeContext:
    | { theme: TBaseComponentTheme & TemporaryThemeOptions; component: string }
    | undefined = useContext(ComponentThemeProviderContext);

  if (!themeContext?.theme) {
    throw new Error(
      'Theme is undefined. Verify this component was rendered dynamically through the component mapper or wrapped with ComponentThemeProvider.'
    );
  }

  return {
    /**
     * Theme definitions can be found in the component's corresponding `theme.ts` file.
     *
     * Component themes are configured in this application's `/config` folder.
     * @see `/config/base-config.ts`
     */
    theme: themeContext.theme,
  };
};
