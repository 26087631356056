import Icon from '@dx-ui/osc-icon';
import type { IconNames } from '@dx-ui/osc-icon';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { useTheme } from '../../hooks/use-theme';

import type honorsTranslations from '../../i18n/en/honors.json';

type IconName = (typeof IconNames)[number];

const Item = ({ icon, title }: HonorsProps['benefits'][number]) => {
  const { t } = useTranslation('honors');
  const { theme } = useTheme();

  return (
    <li className="flex items-center gap-4 align-middle md:max-w-[350px] lg:max-w-[155px] lg:flex-col lg:text-center">
      <Icon
        name={icon}
        size="2xl"
        className={cx({
          'accent-icon': theme.accent?.icon,
        })}
      />
      <p className="text-balance">{t(title)}</p>
    </li>
  );
};

export type HonorsProps = {
  benefits: {
    icon: IconName;
    title: keyof typeof honorsTranslations;
  }[];
};

const Honors = ({ benefits }: HonorsProps) => {
  const { t } = useTranslation('honors');
  const { isTailored } = useGlobalConfig();
  const { theme } = useTheme();

  return (
    <div className="py-8 lg:py-10">
      <div className="container flex flex-col items-center justify-center gap-4">
        <h2
          className={cx('heading-2xl lg:heading-3xl md:pb-4', {
            'accent-heading': theme.accent?.heading,
          })}
        >
          {t('memberBenefitsHeader')}
        </h2>
        <div className="flex flex-col md:ms-9 md:flex-row md:items-start md:justify-center md:gap-24 lg:ms-0">
          <ul
            className={cx('mx-8 grid grid-cols-1 gap-y-4 md:grid-cols-2', {
              'md:odd:[&_li]:border-e lg:[&_li]:border-none md:odd:[&_li]:pe-8 md:even:[&_li]:ps-12 md:[&_li]:pb-3 md:gap-y-0 [&_li]:border-primary lg:flex lg:[&_li]:me-0 lg:gap-24 lg:odd:[&_li]:pe-0 lg:even:[&_li]:ps-0':
                isTailored,
              'lg:grid-cols-6 lg:gap-24': !isTailored,
            })}
          >
            {benefits.map((benefit) => (
              <Item key={benefit.icon} {...benefit} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Honors;
